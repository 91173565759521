import styled from "styled-components";

export const Text = styled.p((props) => ({
  fontSize: props.$size || "1.8rem",
  fontWeight: props.$fontWeight || 400,
  textAlign: props.$textAlign || "left",
  color: props.$color || "var(--textColor)",
}));

export const SectionTitle = styled.h2((props) => ({
  fontSize: props.$size || "5.5rem",
  fontWeight: 400,
  textAlign: props.$textAlign || "left",
  color: props.$color || "var(--titleColor)",
  "@media (max-width: 590px)": {
    fontSize: "3rem",
  },
}));

export const NavText = styled.li((props) => ({
  fontSize: props.$size || "1.4rem",
  color: props.$color || "var(--whiteColor)",
  fontWeight: 500,
  "@media (max-width: 590px)": {
    fontSize: "1.6rem",
  },
}));

export const Button = styled.button((props) => ({
  fontSize: props.$size || "1.8rem",
  fontWeight: 500,
  width: props.$width || "auto",
  padding: props.$padding || "1.6rem 3.2rem",
  backgroundColor: props.$backgroundColor
    ? props.$backgroundColor
    : props.$secondary
    ? "transparent"
    : "var(--primaryColor)",
  color: props.$color
    ? props.$color
    : props.$secondary
    ? "var(--primaryColor)"
    : "var(--secondaryColor)",
  borderRadius: props.$borderRadius || "5rem",
  outline: "none",
  border: props.$border || "0.2rem solid var(--primaryColor)",
  letterSpacing: "2.5px",
  transition: 'all 0.3s ease-in-out',
  "&:hover": {
    backgroundColor: props.$secondary ? "var(--primaryColor)" : "transparent",
    color: props.$secondary ? "var(--secondaryColor)" : "var(--primaryColor)",
  },
}));

export const Anchor = styled.a((props) => ({
  fontSize: props.$size || "1.8rem",
  fontWeight: 500,
  width: props.$width || "auto",
  padding: props.$padding || "1.6rem 3.2rem",
  backgroundColor: props.$backgroundColor
    ? props.$backgroundColor
    : props.$secondary
    ? "transparent"
    : "var(--primaryColor)",
  color: props.$color
    ? props.$color
    : props.$secondary
    ? "var(--primaryColor) !important"
    : "var(--secondaryColor) !important",
  borderRadius: props.$borderRadius || "5rem",
  outline: "none",
  border: props.$border || "0.2rem solid var(--primaryColor)",
  letterSpacing: "2.5px",
  display: "inline-block",
  transition: 'all 0.3s ease-in-out',
  "&:hover": {
    backgroundColor: props.$secondary ? "var(--primaryColor) !important" : "transparent !important",
    color: props.$secondary ? "var(--secondaryColor) !important" : "var(--primaryColor) !important",
  },
}));
