import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { GeoAltFill, TelephoneFill, EnvelopeFill } from "react-bootstrap-icons";
import { SectionTitle } from "../../../styledComponent";
import styles from "./style.module.scss";
import ContactForm from "../../contactForm";

const ContactSection = () => {
  return (
    <div className={styles.contactSection}>
      <Container className={`sectionContainer`}>
        <Row>
          <Col lg={6} className={styles.contactDetails}>
            <div className={styles.contactBox}>
              <SectionTitle>Head Office/Warehouse-1</SectionTitle>
              <ul className={styles.contactDetailsBox}>
              <li>
                <a href="https://maps.app.goo.gl/a1xpGpShJStM5jSn8">
                  <GeoAltFill />
                  <span>6802 39 Street Leduc, AB T9E 0Z4</span>
                </a>
              </li>
              <li>
                <a href="https://maps.app.goo.gl/EPt42uNe9tvMBHWV8">
                  <GeoAltFill />
                  <span>Unit 2109, 6027-79th AVE SE, CALGARY, AB. T2C 5P1</span>
                </a>
              </li>
                <li>
                  <a href="tel:4032790319">
                    <TelephoneFill />
                    <span>(403) 279-0319</span>
                  </a>
                </li>
                <li>
                  <a href="tel:4032639005">
                    <TelephoneFill />
                    <span>(403) 263-9005</span>
                  </a>
                </li>
                <li>
                  <a href="mailto:info@apfsteel.com">
                    <EnvelopeFill />
                    <span>info@apfsteel.com</span>
                  </a>
                </li>
              </ul>
            </div>
            <div className={styles.contactBox}>
              <SectionTitle>Pipe Yard-2</SectionTitle>
              <ul className={styles.contactDetailsBox}>
                <li>
                  <a href="https://maps.app.goo.gl/GL8kzf6EvPpRmwM57">
                    <GeoAltFill />
                    <span>1405 10St Nisku, Alberta T9E0Z6</span>
                  </a>
                </li>
                <li>
                  <a href="tel:4032790319">
                    <TelephoneFill />
                    <span>(403) 279-0319</span>
                  </a>
                </li>
              </ul>
            </div>
            <div className={styles.contactBox}>
              <SectionTitle>Head Office/Warehouse-2</SectionTitle>
              <ul className={styles.contactDetailsBox}>
                <li>
                  <a href="https://maps.app.goo.gl/a1xpGpShJStM5jSn8">
                    <GeoAltFill />
                    <span>6802 39 Street Leduc, AB T9E 0Z4</span>
                  </a>
                </li>
              </ul>
            </div>
          </Col>
          <Col lg={6}>
            <ContactForm />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ContactSection;
