import { routes } from "./routes";
import goalIcon from "../assets/goal1.png";
import teamIcon from "../assets/team1.png";
import visionIcon from "../assets/vision1.png";
// Product Images
import preGalvanizedTubes1 from "../assets/products/preGalvanizedTubes.jpg";
import preGalvanizedTubes2 from "../assets/products/preGalvanizedTubes2.jpg";
import preGalvanizedTubes3 from "../assets/products/preGalvanizedTubes3.jpg";
import galvanizedIron1 from "../assets/products/galvanizedSteel1.jpg";
import galvanizedIron2 from "../assets/products/galvanizedSteel2.jpg";
import galvanizedIron3 from "../assets/products/galvanizedSteel3.jpg";
import scaffolding1 from "../assets/products/scaffolding1.jpg";
import scaffolding2 from "../assets/products/scaffolding2.jpg";
import mildSteel1 from "../assets/products/mildSteel1.jpg";
import mildSteel2 from "../assets/products/mildSteel2.jpg";
import mildSteel3 from "../assets/products/mildSteel3.jpg";
import hss1 from "../assets/products/hsss1.jpg";
import hss2 from "../assets/products/hss2.jpg";
import hss3 from "../assets/products/hss3.jpg";
import temporaryfence1 from "../assets/products/temporaryfence1.jpg";
import temporaryfence2 from "../assets/products/temporaryfence2.jpg";
import temporaryfence3 from "../assets/products/temporaryfence3.jpg";
import octg1 from "../assets/products/octg1.png";
import hotDip1 from "../assets/products/hotDip1.jpeg";
import hotDip2 from "../assets/products/hotDip2.jpeg";
import hotDip3 from "../assets/products/hotDip3.jpeg";
import galvanizedSteel1 from "../assets/products/astm1.png";
import colorCoated1 from "../assets/products/colorCoated1.png";
import coldRolled1 from "../assets/products/coldRolled1.png";

export const navMenuItems = [
  {
    name: "home",
    link: routes.HOME,
  },
  {
    name: "about",
    link: routes.ABOUT,
  },
  {
    name: "products",
    link: routes.PRODCUTS,
  },
  {
    name: "suppliers",
    link: routes.SUPPLIER,
  },
];

export const aboutValuesContent = [
  {
    image: goalIcon,
    title: "OUR MISSION",
    description:
      "Be the best, to gain the victory for all associates by achieving superior financial results and to grow.",
  },
  {
    image: visionIcon,
    title: "OUR VISION",
    description:
      "APF Vision is to become a global network of associates operating seamlessly for progressive businesses. To build itself as master distributor network of Oil & Gas, Steel, Auto Components and Material Handling Equipment.",
  },
  {
    image: teamIcon,
    title: "OUR CORE VALUES",
    description:
      "APF will never deviate from our core value, even if it delays meeting our vision. APF will neither do not involve in any activity which is considered as wrong, unethical, inappropriate or unlawful in the business or society.",
  },
];

export const footerNavMenuItems = [
  {
    name: "Steel Pipe suppliers calgary",
    link: "/",
  },
  {
    name: "Hollow structural steel tube",
    link: routes.HOLLOW_PIPE,
  },
  {
    name: "Cold rolled steel ",
    link: routes.COLOR_ROLLED_STEEL,
  },
  {
    name: "Hot dipped galvanized steel pipe",
    link: routes.GALVANIZED_STEEL,
  },

  {
    name: "Scaffolding steel pipe",
    link: routes.SCAFFOLDING_PIPES,
  },
  {
    name: "OCTG pipe suppliers",
    link: routes.OCTG_WELDED,
  },
  {
    name: "Color Coated Steel",
    link: routes.COLOR_COATED_STEEL,
  },
  {
    name: "Temporary Steel Fence",
    link: routes.TEMP_FENCE,
  },
  {
    name: "ASTM A36 steel plate",
    link: routes.PRODCUTS,
  },
  {
    name: "Hot Dip Galvanized Wire",
    link: routes.GALVANIZED_STEEL,
  },
  {
    name: "OCTG Welded and Seamless Tubes",
    link: routes.OCTG_WELDED,
  },
  {
    name: "Calgary steel suppliers",
    link: routes.SUPPLIER,
  },
  {
    name: "Galvanized steel pipe suppliers",
    link: routes.CONTACT,
  },
];

export const homeQaContent = [
  "APF maintains the precision and compliance in its supplies to the customers. APF endeavours to supply the products which not only meets but exceeds the applicable industry standards",
  "APF outsources engineered products from the renowned manufacturers in the world. Their state of the art technology and infrastructure reflects from the accuracy and precision maintained in their products",
  "APF assures the compliance of the products to the respective MTRs",
  "APF manages third party auditing of the finished goods at manufacturers’ workplace and shipment is allowed to despatch only if the quality is acceptable as an outcome of the audit",
];

export const productData = [
  {
    id: 1,
    name: "Galvanized Iron Pipes (GI)",
    featured_image: galvanizedIron1,
    description:
      "<p><b>Galvanized (GI) pipes are made from prime quality steel coil and galvanized using the highest quality zinc.</b></p><ul><li>Galvanized Iron (GI) pipes are corrosion and rust resistant pipes that are ideal for the transmission of potable water, natural gas, oil and other fluids. They are also used in fencing, low cost shelters and general fabrication.</li><li>GI pipes are certified as European Conformity Standards (CE) and are manufactured in accordance with the highest international standards (BS EN 10255:2004, ASTM A53, ASTM A795, EN39, SLS829:2009)</li><li>GI pipes are available in nominal diameters of 15mm (1/2”) to 200mm (8”) and in thickness from 1.80mm to 5.40mm.</li></ul>",
    product_feature: [
      {
        title: "APF also offers:",
        content:
          "<p>Swaging<br/>Screwed & socketed pipe<br/>Cut-to-length pipe<br/>Close tolerance cutting<br/>Painted pipe<br/>Red primer pipe<br/>Black pipe, varnished or oiled<br/>Internal seam removal</p>",
      },
      {
        title: "Applications",
        content:
          "<p>Fluid/Oil/Water Transmission<br/>Natural Gas Distribution<br/>Fencing<br/>Low Cost Shelters/Housing<br/>General Fabrication</p>",
      },
      {
        title: "International Standards",
        content:
          "<p>Straightness<br/>Thickness/Weight/Length<br/>Corrosion Resistance<br/>Mass of zinc coating</p>",
      },
      {
        title: "Product Testing Features",
        content:
          "<p>Can bear Hydro pressure up to 50 bar<br/>Leak free (NDT Tested)<br/>Thoroughly tested weld (bending & flattening)</p>",
      },
      {
        title: "Salient Features",
        content:
          "<p>Environmentally friendly. state-of-the-art manufacturing facility<br/>Air blown exterior and steam blown interior<br/>Individually chromated<br/>Guaranteed tube count per bundle<br/>Certification: From manufacturer</p>",
      },
    ],
    productGallery: [galvanizedIron1, galvanizedIron2, galvanizedIron3],
    link: routes.GALVANIZED_IRON_PIPES,
    featured: true,
  },
  {
    id: 2,
    name: "Pre Galvanized Tubes",
    featured_image: preGalvanizedTubes1,
    description:
      "<p><b>Pre-Galvanized Tubes are the industry standard for fence work. Contractors, architects, engineers have used steel fencing to design a variety of shelters, lighting standards and decorative sculptures for street-scapes, parks, bus and light rail stops, shopping centers, malls, sports stadiums, office buildings, parking lots and other facilities. Steel fence are carefully designed with clear lines, structural integrity and ease of installation. These fences are available in variety of heights and widths in multiple configurations.</b></p><ul><li>Pre-Galvanized Tubes have high durability, weld consitency, reliability, easy to mold, superior finish anti-rust coating and strict adherence to international quality standards. IIL Pre-Galvanized Tubes are manufactured in accordance to BS EN10305-3.</li><li>Pre-Galvanized Tubes are available in round, square and rectangle shapes with thickness range from 0.8mm to 1.50mm.</li></ul>",
    product_feature: [
      {
        title: "Product Features",
        content:
          "<p>Best quality and value for money<br/>Durability and high tensile strength</p>",
      },
      {
        title: "Applications",
        content:
          "<p>Road Barriers<br/>Gates & Windows<br/>Road Side Railings<br/>Industrial Gates<br/>Barrier Gates<br/>Polo Club Fence<br/>Sports Facilities <br/>Steel Roofing<br/>Parking Barriers<br/>Horse Riding Fence<br/>Parks/Playgrounds<br/>Horse Stables<br/>Temporary Fencing<br/>Green Houses<br/>Parking Lots<br/>Steel Buildings</p>",
      },
      {
        title: "Salient Features",
        content:
          "<p>Environmentally friendly. state-of-the-art manufacturing facility<br/>Air blown exterior and steam blown interior<br/>Individually chromated<br/>Guaranteed tube count per bundle<br/>Certification: From manufacturer</p>",
      },
    ],
    productGallery: [
      preGalvanizedTubes1,
      preGalvanizedTubes2,
      preGalvanizedTubes3,
    ],
    link: routes.PRE_GALVANIZED_TUBES,
    featured: true,
  },
  {
    id: 3,
    name: "Scaffolding Pipe (Safescaf)",
    featured_image: scaffolding1,
    description:
      "<p><b>Safescaf tubes are easy to assemble and suitable for all types of scaffolding projects. A premium quality steel tube that is durable, light weight and high strength, IIL Safescaf is manufactured in accordance with the highest international standards (BS EN 39:2001)</b></p><ul><li>These scaffolding tubes have high tensile strength and are widely demanded for various scaffolding applications.</li><li>The lengths can be provided in cut-to-length also as per customer demand. The full length pipe can be made up to 7.3152 meters. The scaffolding pipes can be galvanized as per customer requirement according to BS EN 10240 and are available in 48.3mm diameter in Type 2,3 & 4.</li></ul>",
    product_feature: [
      {
        title: "Product Features",
        content:
          "<p>Best quality at affordable prices<br/>Durability and high tensile strength<br/>Availability of cut-to-length sizes as per requirement<br/>Can be embossed & printed length-wise as per requirement<br/>Corrosion/rust resistant galvanized Safescaf available</p>",
      },
      {
        title: "Benefits of Safescaf",
        content:
          "<p>Long-term cost savings<br/>Greater load carrying capability<br/>Fewer couplers, standards, ledgers & transoms required</p>",
      },
      {
        title: "Guaranteed Compliance to International Standards",
        content:
          "<p>Straightness<br/>Thickness/Weight/Length<br/>Corrosion resistant (Galvanized Safescaf)<br/>Mass of zinc coating (Galvanized Safescaf)<br/>Conformity to BS EN 39:2001</p>",
      },
      {
        title: "Product Testing Features",
        content:
          "<p>Thoroughly tested weld (Bending, Flattening & Drift Expansion)</p>",
      },
      {
        title: "Salient Features",
        content:
          "<p>Environmentally friendly<br/>Guaranteed tube count per bundle<br/>Certification: From manufacturer</p>",
      },
    ],
    productGallery: [scaffolding1, scaffolding2],
    featured: true,
    link: routes.SCAFFOLDING_PIPES,
  },
  {
    id: 4,
    name: "MS Pipe & Black Pipe",
    featured_image: mildSteel1,
    description:
      "<p><b>MS pipes are made in accordance to BS EN 10219 and reduces total project costs for labour, installation, shipping, administration and manufacturing.</b></p><ul><li>These pipes can be used for structural applications as well as fluid transmission.</li><li>These high quality MS pipes are made in IIL's state-of-the-art facility and are backed by the company's promise of quality, trust & reliability that has been its hallmark for more than 50 years.</li></ul>",
    product_feature: [
      {
        title: "Product Features",
        content:
          "<p>High strength-to-weight ratios<br/>Uniform strength<br/>Cost-effective<br/>Short construction time frames<br/>Highly recyclable<br/>Greater design flexibility</p>",
      },
      {
        title: "Applications",
        content:
          "<p>Pressure Lines<br/>Power Stations<br/>Water Supply<br/>Steel Constructions<br/>Fencing<br/>Ship Building<br/>Bridges & Buildings<br/>Mechanical Components</p>",
      },
      {
        title: "Guaranteed Compliance To International Standards (BS EN 10219)",
        content:
          "<p>Thickness/Weight/Length<br/>Straightness<br/>NDT Tested</p>",
      },
      {
        title: "Product Testing Features",
        content: "<p>Thoroughly tested weld (Flattening)</p>",
      },
      {
        title: "Salient Features",
        content:
          "<p>IIL embossing<br/>Black rust preventive coating<br/>Burr free edges<br/>Thoroughly tested (Tensile, Impact testing)<br/>Guaranteed tubes count per bundle</p>",
      },
    ],
    productGallery: [mildSteel1, mildSteel2, mildSteel3],
    featured: true,
    link: routes.MS_PIPES,
  },
  {
    id: 5,
    name: "Hollow Structural Sections (HSS)",
    featured_image: hss1,
    description:
      "<p><b>Hollow structural sections can be manufactured according to BS EN 10219, ASTM A500, ASTM A53 and ASTM A252 international quality standards, which reflects to high quality manufacturing.</b></p><ul><li>Hollow Structural Sections are steel sections that are used in a variety of structural applications.</li><li>APF imports circular hollow sections (CHS), square hollow sections (SHS) and rectangular hollow sections (RHS) in various sizes, thicknesses and material grades.</li></ul>",
    product_feature: [
      {
        title: "Applications",
        content:
          "<p>Commercial Buildings<br/>Warehouses/Sheds<br/>Pedestrian Walkways<br/>Overhead Bridges<br/>Residential Buildings<br/>Infrastructural Projects<br/>General Fabrication</p>",
      },
      {
        title: "Product Features",
        content:
          "<p>Quick construction<br/>Reduced overall project costs<br/>Versatility of design<br/>Environmentally friendly<br/>Faster Return on investment<br/>High salvage value</p>",
      },
      {
        title: "Guaranteed Compliance To International Standards",
        content:
          "<p>Thickness<br/>Weight<br/>Length<br/>Straightness<br/>NDT tested</p>",
      },
      {
        title: "Salient Features",
        content:
          "<p>IIL embossing<br/>Black rust preventive coating<br/>Burr free edges<br/>Thoroughly tested (Tensile, Impact testing)<br/>Guaranteed tubes count per bundle</p>",
      },
    ],
    productGallery: [hss1, hss2, hss3],
    featured: false,
    link: routes.HOLLOW_PIPE,
  },
  {
    id: 6,
    name: "Temporary Fence",
    featured_image: temporaryfence1,
    description:
      "<p><b>No matter how big or small the project, you will have a complete and customizable temporary fencing system to suit the unique requirements of your project. We carry 4’, 6’, and 8’ tall fence, all in 2′, 4′, 6′ and 10′ lengths.</b></p><ul><li>They are built on a square tube frame with a welded wire mesh.</li><li>Our innovative, freestanding temporary fence panels are tougher than any other on the market, which means your construction site or event space will remain safe and secure.</li><li>We carry 4’, 6’, and 8’ tall fence, all in 2′, 4′, 6′ and 10′ lengths.</li></ul>",
    product_feature: [
      {
        title: "Everything you need for any application",
        content:
          "<p>Need man gates? Vehicle gates? Pedestrian barriers? Castors for vehicle access gates? Couplers? Security clamps and privacy screening? We have those too No matter how big or small the project, rest assured you will have a complete and customizable temporary fencing system to suit the unique requirements of your project.</p>",
      },
    ],
    productGallery: [temporaryfence1, temporaryfence2, temporaryfence3],
    featured: false,
    link: routes.TEMP_FENCE,
  },
  {
    id: 7,
    name: "OCTG Welded and Seamless Tubes",
    featured_image: octg1,
    description:
      "<p><b>The term Oil Country Tubular Goods describes tubes that are used in oil and gas production: As a rule these include drill pipe, casing and tubing. Oil Country Tubular Goods are used both onshore and offshore. In the industry, they are also referred to by the acronym OCTG (for “Oil Country Tubular Goods”).</b></p><p><b>There are three main types of Oil Country Tubular Goods, which includes Drill pipe, Casing pipe and Tubing pipe.</b></p><p><b>Size Size w/t (“) Label 2 w/t (mm)</b></p><p><b>114.30mm 4-1/2″ 0.224 10.500 5.69</b><br/><b>114.30mm 4-1/2″ 0.250 11.600 6.35</b><br/><b>114.30mm 4-1/2″ 0.290 13.050 7.37</b><br/><b>139.70mm 5-1/2″ 0.275 15.500 6.98</b><br/><b>139.70mm 5-1/2″ 0.304 17.000 7.72</b><br/><b>139.70mm 5-1/2″ 0.361 20.000 9.17</b><br/><b>177.80mm 7-5/8″ 0.408 29.000 10.36</b><br/><b>177.80mm 7-5/8″ 0.453 32.000 11.51</b><br/><b>219.10mm 8-5/8″ 0.264 24.000 6.71</b><br/><b>219.10mm 8-5/8″ 0.352 32.000 8.94</b><br/><b>244.48mm 9-5/8″ 0.352 36.000 8.94</b><br/><b>244.48mm 9-5/8″ 0.395 40.000 10.03</b><br/><b>273.00mm 10-3/4″ 0.350 40.500 8.89</b><br/><b>273.00mm 10-3/4″ 0.400 45.500 10.16</p>",
    product_feature: [
      {
        title: "Casing",
        content:
          "<p>4 1/2” to 13 3/8” (114.3mm to 339.7mm) SMLS & ERW API Range 3 with STC & LTC connections</p><ul><li>Carbon Grades: J55, K55, J55D10,</li><li>Alloy Grades: L80, L80D10 & P110</li><li>Other Grades: T95, Mild Sour P110, High Performance P110 & L80</li><li>Specialty Grades available upon request</li><li>Semi Premium and Premium connections available upon request</li></ul>",
      },
      {
        title: "Tubing",
        content:
          "<p>API standard: J55, L80, P110, SMLS & ERW Range 2 with EUE connection</p><ul><li>2 3/8” x 4.7# (60.3mm x 6.99kg/m)</li><li>2 7/8” x 6.5# (73.0mm x 9.67kg/m)</li><li>3 ½” x 9.3# (88.9mm x 13.84kg/m)</li><li>4 ½” x 12.6# (114mm x 18.75kg/m)</li><li>Macaroni 1”, 1 1/4”, 1 1/2”, 2 1/16” API Grades: J55 (IJ10RND)</li></ul><p>Specialty Grades, Weights and Connections available upon request.</p>",
      },
      {
        title: "Line Pipe",
        content:
          "<p>Line pipe is very strong carbon steel pipes which are used for the transportation of various petroleum products, natural gas, oil and water. API 5L is a specification of American Petroleum Institute which regulates and defines the standard for the dimension, physical, mechanical and chemical properties of the steel used for the manufacturing of line pipe. Numerous pipe manufacturing companies across the globe follow the API 5L guidelines for the OCTG line pipe used in the Oil and Gas industry. OCTG line pipe is available in a wide range of diameter sizes ranging from 2 inches to 48 inches.</p>",
      },
      {
        title: "Seamless line pipe",
        content:
          "<p>Seamless line pipe is OCTG pipelines used for transportation of crude oil and natural gas from the rig field to the oil refineries and storage facilities. They are also useful for transportation to the different shipment and distribution points. As the name suggests, Seamless pipe is pipe without a seam or welded joint. In welded pipe, the joint where the pipe is welded is the weakest part, whereby limiting the strength of the pipe. Since Seamless pipe does not have any welded joints, they have a uniform structure and strength. They can endure and hold up against high pressure, high temperature and high mechanical stress. They are corrosive resistant in nature, making them ideal for use in the Oil, Gas and Petroleum industry. Seamless line pipe is manufactured by heating cylindrical bars of steel at very high temperatures. When the pipe reaches the required temperature and becomes red hot, they are pierced with a probe for creating the required size holes into it. After the hole is pierced, the red hot cylindrical bar is then processed on rollers for acquiring the required wall thickness and diameter.</p>",
      },
      {
        title: "Welded line pipe",
        content:
          "<p>Welded line pipe is useful for interstate transportation of oil and gas. It is used for intra-field transportation of crude oil and natural gas from the rig field to the oil refineries, distribution points and storage facilities. Welded pipelines are usually huge diameter pipelines which are manufactured by converting a steel plate into a cylindrical shape. The seam of the pipe is closed by the welding process. The quality of the welded seam is monitored under the ultrasonic or radiological inspection method. A Pressure test is performed on each of the joints of the welded line pipe to determine its strength and pressure taking capability. The pipe undergoes quality checks for the quality of steel used, the pipe dimensions, its strength and sturdiness.</p>",
      },
      {
        title: "Types of Welded Line Pipe",
        content:
          "<p><b>LSAW Pipe</b> (Longitudinal Submerged Arc Welding)<br/>An LSAW pipe is manufactured by cutting, bending and welding steel plates. </p><p><b>ERW Pipe</b> (Electric Resistance Welding)<br/>ERW pipe is manufactured using steel coils which are first uncoiled, cut, processed, welded and tested.</p>",
      },
    ],
    productGallery: [octg1],
    featured: false,
    link: routes.OCTG_WELDED,
  },
  {
    id: 8,
    name: "Hot Dip Galvanized Wire",
    featured_image: hotDip1,
    description:
      "<p><b>We can supply super quality hot dipped galvanized wire made of low carbon steel and high carbon steel.</b></p>",
    product_feature: [
      {
        title: "Processing of Hot-dip Galvanized Wire",
        content:
          "<p>APF imports hot-dip galvanized wire from choiced low/high carbon steel wire, through the strict process of wire drawing-annealing-acid washing-water clearing-drying-galvanizing-coiling, in this way, excellent flexibility and tensile strength could be guaranteed.</p>",
      },
      {
        title: "Technical/Mechanical Characters",
        content:
          "<p><b>ASTM Standard: </b>A641/A641M-98</p><p><b>Material: </b>SAE1006/1008,Q195/235 SAE1050/1065</p><p><b>Zinc coating: </b>0.10Oz-1.60Oz/Sq.feet;</p><p><b>Commercial grade: </b>>60grams/sq.m</p><p><b>Heavy coating: </b>>200grams/sq.m >250grams/sq.m</p><p><b>Diameter range:  </b>0.50-4,90mm</p><p><b>T.S:  </b>300/550Mpa, 350/450Mpa, 450-550Mpa, 600-800Mpa, 700-900Mpa 1100-1300Mpa, 1300-1700Mpa etc.</p><p><b>Packing:  </b>1kg-800kgs/orbit coil</p>",
      },
      {
        title: "Application",
        content:
          "<p>This kind of wire is extensively used in construction, handicrafts, woven wire mesh, express way fencing mesh, packaging of products and other daily uses.</p>",
      },
    ],
    productGallery: [hotDip1, hotDip2, hotDip3],
    featured: false,
    link: routes.GALVANIZED_WIRE,
  },
  {
    id: 9,
    name: "Hot Dipped Galvanized Steel",
    featured_image: galvanizedSteel1,
    description:
      "<p><b>Galvanization is the process of applying a protective zinc coating to steel, to prevent rust and corrosion. ISL’s Hot Dip Galvanized steel is coated with molten zinc and processed on an automated production line. This product is offered in bright finish with regular or minimum spangle for general fabrication.</b></p><p>APF imports Hot Dip Galvanized Steel which is produced on a state-of-the-art, fully automated production line. The best available raw materials and processes are applied under controlled conditions to produce material of the highest quality.</p><ul><li><b>Thickness</b> - 0.15mm - 2.50mm</li><li><b>Grades</b> - As per JIS G3302 and equivalent standards</li><li><b>Maximum Width</b> - 1250mm</li></ul>",
    product_feature: null,
    productGallery: [galvanizedSteel1],
    featured: false,
    link: routes.GALVANIZED_STEEL,
  },
  {
    id: 10,
    name: "Color Coated Steel",
    featured_image: colorCoated1,
    description:
      "<p><b>The color coated steel imported at APF is stain-resistant, corrosion-resistant and scratch-resistant which makes it ideal for various commercial and industrial applications. The high quality color coating enhances the corrosion resistance of substrates and adds to their aesthetic appeal.</b></p><p>The 84,000 MT state-of-the-art facility processes and coats a wide range of substrates like cold rolled, galvanized, galvalume, aluminum and stainless steel, in any imaginable color.</p><p>The high quality color coating enhances the corrosion resistance of substrates and adds to their aesthetic appeal. The color coated steel imported by APF is stain-resistant, corrosion-resistant and scratch-resistant which makes it ideal for various commercial and industrial applications.</p><ul><li><b>Thickness</b> - 0.20 mm – 1.50 mm</li><li><b>Grades</b> - As per JIS G3312 and equivalent standards.</li><li><b>Maximum Width</b> - 1250mm</li></ul>",
    product_feature: null,
    productGallery: [colorCoated1],
    featured: false,
    link: routes.COLOR_COATED_STEEL,
  },
  {
    id: 11,
    name: "Cold Rolled Steel",
    featured_image: coldRolled1,
    description:
      "<p><b>APF imports Cold Rolled Steel which is manufactured on a modern, state-of-the-art Cold Rolling mill designed by SMS Siemag, Germany. This product is available in several strength grades, in bright and matt finishes, for general fabrication, drawing and industrial applications.</b></p><p>APF’s cold rolled steel is highly valued by customers in the automobile, home appliances, furniture, drum, tube, filter, tin plate and various other industrial segments. </p><p>APF’s CRC is provided in a range of product specifications, ranging from drawing to structural, to meet our customers’ specific requirements.</p><ul><li><b>MAX. WIDTH & THICKNESS</b> -1,250 mm - 0.15 mm – 3.00 mm</li><li><b>Grades</b> - As per JIS G3141 and equivalent standards.</li><li><b>SURFACE FINISHING</b> - Bright - Matt</li></ul>",
    product_feature: null,
    productGallery: [coldRolled1],
    featured: false,
    link: routes.COLOR_ROLLED_STEEL,

  },
];

export const productMainSliderSettings = {
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  fade: true,
  // asNavFor: ".slider-nav",
};

export const productGallerySliderSettings = {
  slidesToShow: 3,
  slidesToScroll: 1,
  // asNavFor: ".slider-for",
  dots: true,
  centerMode: true,
  focusOnSelect: true,
};
