import * as Yup from "yup";

export const contactFormValidationSchema = Yup.object({
  name: Yup.string().required(),
  phone: Yup.string().required(),
  email: Yup.string().email().required(),
});

export const supplierFormValidationSchema = Yup.object({
  name: Yup.string().required(),
  phone: Yup.string().required(),
  email: Yup.string().email().required(),
  companyName: Yup.string().required(),
  productSupply: Yup.string().required(),
});
