import React from "react";
import { Helmet } from "react-helmet";
import styles from "./style.module.scss";
import { routes } from "../../utils/routes";
import {
  BackgroundOverlay,
  BannerVideoContainer,
  LineDivider,
  ProductCard,
} from "../../components/ui";
import { Container, Row, Col } from "react-bootstrap";
import {
  Anchor,
  Button,
  SectionTitle,
  Text,
} from "../../components/styledComponent";
// Importing Images
import aboutImg from "../../assets/homeAboutSec.jpg";
import { homeQaContent, productData } from "../../utils/constants";
import {
  ChevronRight,
} from "react-bootstrap-icons";
import { ContactSection } from "../../components/parts/section";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();
  const featuredProduct = productData.filter(val => val.featured && val)
  return (
    <>
      <Helmet>
        <title>Calgary Steel Distributor & Metal Processing Company | APF Steel</title>
        <meta
          name="description"
          content="APF Steel is a top steel manufacturer and supplier that provides metal processing services as well as structural steel products. Call today!"
        />
      </Helmet>
    <div className={styles.homePage}>
      {/* Banner section */}
      <div className={styles.bannerSection}>
        <BannerVideoContainer />
        <BackgroundOverlay />
        <Container className={styles.bannerContent}>
          <Row>
            <Col lg={6}>
              <SectionTitle>
                EXPERTS IN STEEL INFRASTRUCTURE AND STEEL GOODS
              </SectionTitle>
              <Text>
                APF is a master distribution house under visionary leadership,
                working with a team of dedicated and experienced personnels.
              </Text>
              <Button onClick={() => navigate(routes.PRODCUTS)}>EXPLORE OUR PRODUCT</Button>
            </Col>
          </Row>
        </Container>
      </div>

      {/* About section */}
      <Container className={`sectionContainer ${styles.aboutSection}`}>
        <Row>
          <Col lg={7}>
            <div className={styles.aboutBgImage}></div>
            <img src={aboutImg} alt="About APF" />
          </Col>
          <Col lg={5}>
            <SectionTitle>About us</SectionTitle>
            <LineDivider />
            <Text>
              Founded in 2021 as part of the APF Group, APF Steel offers a wide
              range of structural steel products, including temporary fences,
              hot dipped galvanized steel, cold rolled steel, color coated
              steel, OCTG welded steel tubes, and seamless tubes. As one of
              Canada’s largest steel distributors, we are dedicated to supplying
              quality steel products in a range of grades, widths, and shapes to
              our clients. Our top priorities at APF Steel are quality products,
              affordability, and commitment to our clients. Core Values
            </Text>
            <div className={styles.coreValuesBox}>
              <SectionTitle>Core Values</SectionTitle>
              <Text>
                We neither do nor involve in any activity which is considered as
                wrong, unethical, inappropriate or unlawful in the business or
                society. We never deviate from our core value, even if it delays
                meeting our vision.{" "}
              </Text>
            </div>
            <Anchor href={routes.ABOUT}>LEARN MORE</Anchor>
          </Col>
        </Row>
      </Container>

      {/* QA section */}
      <div className={styles.qaSection}>
        <Container className={`sectionContainer`}>
          <Row>
            <Col lg={6}>
              <SectionTitle>Quality Assurance</SectionTitle>
              <LineDivider />
              <ul className={styles.qaList}>
                {homeQaContent.map((val, i) => {
                  return (
                    <li key={i}>
                      <ChevronRight /> <Text>{val}</Text>
                    </li>
                  );
                })}
              </ul>
            </Col>
            <Col lg={6} className={styles.qaImage}></Col>
          </Row>
        </Container>
      </div>

      {/* Product section */}
      <Container className={`${styles.productSection}`}>
        <Row>
          <Col>
            <SectionTitle>Our Products</SectionTitle>
            <LineDivider />
          </Col>
        </Row>
        <Row className={styles.productList}>
          {featuredProduct.map((val, i) => {
            return (
              <Col lg={3} key={i}>
                <ProductCard
                  title={val.name}
                  image={val.featured_image}
                  link={val.link}
                />
              </Col>
            );
          })}
        </Row>
        <Row>
          <Col style={{marginTop: "7rem", textAlign: "center" }}><Anchor href={routes.PRODCUTS}>VIEW MORE PRODUCTS</Anchor></Col>
        </Row>
      </Container>

      {/* Contact section */}
      <ContactSection />
    </div>
    </>
  );
};

export default Home;
