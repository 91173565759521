import React from "react";
import { Helmet } from "react-helmet";
import { PageBanner } from "../../components/parts/section";
import styles from "./style.module.scss";
import { Col, Container, Row } from "react-bootstrap";
import { productData } from "../../utils/constants";
import { ProductCard } from "../../components/ui";
import productBannerImg from "../../assets/products/productPageBg.jpg";

const Products = () => {
  return (
    <>
      <Helmet>
        <title>
          ASTM Steel Plate, HSS Steel Pipe, Galvanized Steel Channel
        </title>
        <meta
          name="description"
          content="Contact APF if you require metal angles, steel beams, steel pipes, plates or more. We deliver steel products in Calgary & surrounding areas."
        />
      </Helmet>
      <div className={styles.productPage}>
        <PageBanner
          title="Our Products"
          style={{
            backgroundImage: `url(${productBannerImg})`,
            backgroundAttachment: "fixed",
          }}
        />
        <Container className={`sectionContainer ${styles.productList}`}>
          <Row>
            {productData.map((val, i) => {
              return (
                <Col lg={3} key={i}>
                  <ProductCard
                    title={val.name}
                    image={val.featured_image}
                    link={val.link}
                  />
                </Col>
              );
            })}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Products;
