import React from "react";
import styles from "./style.module.scss";
import { Button, Text } from "../../styledComponent";

const ProductCard = (props) => {
  return (
    <div className={styles.productCard}>
      <div className={styles.productImg}>
        <img src={props.image} alt={props.title} />
      </div>
      <Text>{props.title}</Text>
      <Button onClick={() => (window.location.href=props.link)}>VIEW DETAILS</Button>
    </div>
  );
};

export default ProductCard;
