import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import styles from "./styles.module.scss";
import { ChevronDown } from "react-bootstrap-icons";

const FaqBox = ({ id, title, description, currentFaq, currentQues }) => {
  return (
    <li
      className={
        currentQues === id
          ? `${styles.activeFaq} ${styles.faqBox}`
          : styles.faqBox
      }
      onClick={() => {
        currentQues === id ? currentFaq(false) : currentFaq(id);
      }}
    >
      <motion.button
        initial={false}
        className={styles.faqHeader}
        whileTap={{ scale: 0.97 }}
      >
        <span>{title}</span>
        <span>
          <ChevronDown size={15} />
        </span>
      </motion.button>
      <AnimatePresence initial={false}>
        {currentQues === id && (
          <motion.div
            className={styles.faqBody}
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{
              duration: 0.4,
              cubicBezier: [0.455, 0.03, 0.515, 0.955],
            }}
            dangerouslySetInnerHTML={{ __html: description }}
          ></motion.div>
        )}
      </AnimatePresence>
    </li>
  );
};

export default FaqBox;
