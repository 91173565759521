import React, { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { BurgerMenu } from "../../ui";
import MenuSlider from "../menuSlider";
import { Button, NavText } from "../../styledComponent";
import { navMenuItems } from "../../../utils/constants";
import { Container, Row, Col } from "react-bootstrap";
import Logo from "../../../assets/logo.png";
import styles from "./style.module.scss";
import { routes } from "../../../utils/routes";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <header>
      <div className={styles.bottomHeader}>
        <Container>
          <Row>
            <Col md={4} lg={4} className={styles.logoContainer}>
              <Link to={"/"}>
                <img src={Logo} alt="Your Massage" />
              </Link>
            </Col>
            <Col lg={8} className={styles.headerColTwo}>
              <ul className={styles.mainMenu}>
                {navMenuItems.map((item, i) => (
                  <NavText key={i}>
                    <NavLink to={item.link}>{item.name}</NavLink>
                  </NavText>
                ))}
                <li>
                  <Button onClick={() => navigate(routes.CONTACT)}>
                    Contact
                  </Button>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={styles.mobileHeader}>
        <div>{/* <h1>hi</h1> */}</div>
        <div className={styles.mobileLogo}>
          <img src={Logo} alt="Your Massage" />
        </div>
        <div className={styles.burgerMenuContainer}>
          <BurgerMenu open={(val) => setIsOpen(val)} isOpen={isOpen} />
        </div>
        <MenuSlider closeMenu={(val) => setIsOpen(val)} isOpen={isOpen} />
      </div>
    </header>
  );
};

export default Header;
