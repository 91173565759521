import React from "react";
import { Helmet } from "react-helmet";
import styles from "./styles.module.scss";
import { PageBanner } from "../../components/parts/section";
import { Container, Row, Col } from "react-bootstrap";
import { SectionTitle, Text } from "../../components/styledComponent";
import { BackgroundOverlay } from "../../components/ui";
import coreValuesImg from "../../assets/aboutValues.png";
import { aboutValuesContent } from "../../utils/constants";

const About = () => {
  return (
    <>
      <Helmet>
        <title>
          About APF Steel | Order HSS Steel Beam, Pipe, Cold Rolled Steel
        </title>
        <meta
          name="description"
          content="We at APF Steel provide a range of convenient and cost-effective structural steel solutions to meet your structural steel requirements."
        />
      </Helmet>
      <div className={styles.aboutPage}>
        <PageBanner title="ABOUT US" />

        {/* Second section */}
        <Container className={`sectionContainer ${styles.aboutSecondSection}`}>
          <Row>
            <Col lg={5}>
              <SectionTitle>
                Founded in 2010, with the leadership having 30 years of
                experience in the industry
              </SectionTitle>
              <BackgroundOverlay color={"rgb(255,255,255,0.9)"} />
            </Col>
            <Col lg={7}>
              <Text>
                APF Group was formed under Garry’s leadership in 2010
                with the foundation of Alberta Pipe Fittings Limited to service
                the Oil and Gas industry. APF diversified its product range
                under APF Group in the steel sector in response to grow in steel
                sector.
                <br />
                <br />
                We are a professionally managed company that is committed on
                building relations and satisfying our customers needs. When it
                comes to quality products at competitive prices, we have earned
                a reputation as a consistent global supplier of innovation,
                quality and value. This makes APF Group an ideal partner for any
                customer looking for dependability and consistency when it comes
                to their industrial needs.
              </Text>
            </Col>
          </Row>
        </Container>

        {/* Director note section */}
        <div className={styles.directorNoteSection}>
          <Container className={`sectionContainer`}>
            <Row>
              <Col lg={4}>
                <SectionTitle>Director's Note </SectionTitle>
              </Col>
              <Col lg={8}>
                <blockquote>
                  <Text>
                    APF – A name of trust, reliability and honesty along with
                    mantra of “Youth, dynamism and aggressiveness” to serve our
                    customers, our suppliers, existing and future employees. Our
                    commitment to grow with our core values of ethics and full
                    of respect encourages all our partners to drive us to new
                    heights. Welcome and thank you of all to be part of this
                    journey as we take it on. With the objective of growth
                    optimization and prosperity to move ahead, APF endeavors to
                    expand domestic and overseas business. We have the vision to
                    always explore new horizons, keeping constant and healthy
                    efforts. Challenges we face everyday, everyday being a new
                    day, we rise to meet these challenges with commitment and
                    fortitude.
                  </Text>
                </blockquote>
              </Col>
            </Row>
          </Container>
        </div>

        {/* Values section */}
        <Container className={`sectionContainer ${styles.valuesContainer}`}>
          <Row>
            <Col lg={6}>
              <img src={coreValuesImg} alt="APF CORE VALUES" />
            </Col>
            <Col lg={6} className={styles.valuesContent}>
              {aboutValuesContent.map((val, i) => {
                return (
                  <div className={styles.valueBox}>
                    <div className={styles.valueImg} key={i}>
                      <img src={val.image} alt="Apf steel mission" />
                    </div>
                    <div className={styles.content}>
                      <SectionTitle>{val.title}</SectionTitle>
                      <Text>{val.description}</Text>
                    </div>
                  </div>
                );
              })}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default About;
