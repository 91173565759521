import React from "react";
import styles from "./styles.module.scss";

const BackgroundOverlay = (props) => {
  return (
    <div
      className={styles.overlayContainer}
      style={{
        backgroundColor: `${props.color ? props.color : "rgb(0,0,0,0.4)"}`,
      }}
    ></div>
  );
};

export default BackgroundOverlay;
