import React from "react";
import { Helmet } from "react-helmet";
import styles from "../style.module.scss";
import { PageBanner } from "../../../../components/parts/section";
import { productData } from "../../../../utils/constants";
import { CommonProductPage } from "../../../../components/parts";

const ScaffoldingPipes = () => {
  const singleProductData = productData.filter((val) => val.id === 3);
  return (
    <>
      <Helmet>
        <title>
          Scaffolding Pipe | Custom Scaffolding Solutions | Safescaf Tubes
        </title>
        <meta
          name="description"
          content="APF Steel is a trusted manufacturer of high-quality scaffold products and shoring equipment in Calgary, Canada."
        />
      </Helmet>
      <div className={styles.productInnerPage}>
        <PageBanner image={singleProductData[0].featured_image} />
        <CommonProductPage
          title={singleProductData[0].name}
          description={singleProductData[0].description}
          features={singleProductData[0].product_feature}
          gallery={singleProductData[0].productGallery}
        />
      </div>
    </>
  );
};

export default ScaffoldingPipes;
