import React from "react";
import styles from "./style.module.scss";

const BannerVideoContainer = () => {
  return (
    <div className={styles.videoContainer}>
      <video
        autoPlay
        muted
        playsInline
        loop
        src={
          "https://customer-wnwmjzjhs9x0dezp.cloudflarestream.com/cd38d7d4d423563cd73b18c39b8c57fa/downloads/default.mp4"
        }
        style={{ width: "100vw", height: "854.438px" }}
      ></video>
    </div>
  );
};

export default BannerVideoContainer;
