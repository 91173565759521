import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import styles from "./style.module.scss";
import { navMenuItems } from "../../../utils/constants";
import { Button } from "../../styledComponent";
import { routes } from "../../../utils/routes";

const MenuSlider = (props) => {
  const navigate = useNavigate();
  const show = {
    scaleX: 1,
    transformOrigin: "right",
  };

  const hide = {
    scaleX: 0,
    transformOrigin: "right",
    transitionEnd: {
      scaleX: 0,
    },
  };

  return (
    <motion.div
      animate={props.isOpen ? show : hide}
      transition={{ duration: 0.5 }}
      className={`${styles.menuSliderContainer} mobileMenuBlock`}
    >
      <ul>
        {navMenuItems.map((item, i) => (
          <motion.li
            key={i}
            animate={props.isOpen ? { opacity: 1 } : { opacity: 0 }}
          >
            <NavLink to={item.link} onClick={() => props.closeMenu(false)}>
              {item.name}
            </NavLink>
          </motion.li>
        ))}

        <motion.li
          animate={props.isOpen ? { opacity: 1 } : { opacity: 0 }}
          style={{ textAlign: "center" }}
        >
          <Button
            onClick={() => {
              props.closeMenu(false);
              navigate(routes.CONTACT);
            }}
            $width="100%"
          >
            Contact
          </Button>
        </motion.li>
      </ul>
    </motion.div>
  );
};

export default MenuSlider;
