import React from "react";
import { useFormik } from "formik";
import styles from "./style.module.scss";
import { contactFormInitialValues } from "../../../utils/formik/initialvalue";
import { contactFormValidationSchema } from "../../../utils/formik/validationSchema";
import { Button } from "../../styledComponent";

const ContactForm = () => {
  const formik = useFormik({
    initialValues: contactFormInitialValues,
    validationSchema: contactFormValidationSchema,
  });

  return (
    <div className={styles.formBlock}>
      <form
        // onSubmit={formik.handleSubmit}
        method="POST"
        name="contactForm"
        className="contactForm"
        netlify
        data-netlify="true"
      >
        <input type="hidden" name="form-name" value="contactForm" />
        <div className={styles.fieldContainer}>
          <div>
            <label>Name*</label>
            <input
              type="text"
              {...formik.getFieldProps("name")}
              style={
                formik.touched.name && formik.errors.name
                  ? { borderColor: "red" }
                  : null
              }
            />
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <div>
            <label>Phone*</label>
            <input
              type="text"
              {...formik.getFieldProps("phone")}
              style={
                formik.touched.phone && formik.errors.phone
                  ? { borderColor: "red" }
                  : null
              }
            />
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <div>
            <label>Email*</label>
            <input
              type="email"
              {...formik.getFieldProps("email")}
              style={
                formik.touched.email && formik.errors.email
                  ? { borderColor: "red" }
                  : null
              }
            />
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <div>
            <label>PRODUCTS YOU ARE INTERESTED IN</label>
            <textarea
              {...formik.getFieldProps("productIntrestedIn")}
              rows={8}
              style={
                formik.touched.productIntrestedIn && formik.errors.productIntrestedIn
                  ? { borderColor: "red" }
                  : null
              }
            />
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <Button
            className={
              !(formik.isValid && formik.dirty) ? "disabledBtn" : "submitBtn"
            }
            type="submit"
          >
            SUBMIT
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
