import React from "react";
import { useFormik } from "formik";
import styles from "./style.module.scss";
import { supplierFormInitialValues } from "../../../utils/formik/initialvalue";
import { supplierFormValidationSchema } from "../../../utils/formik/validationSchema";
import { Button } from "../../styledComponent";

const SupplierForm = () => {
  const formik = useFormik({
    initialValues: supplierFormInitialValues,
    validationSchema: supplierFormValidationSchema,
  });

  return (
    <div className={styles.formBlock}>
      <form
        // onSubmit={formik.handleSubmit}
        method="POST"
        name="supplierForm"
        className="supplierForm"
        netlify
        data-netlify="true"
      >
        <input type="hidden" name="form-name" value="supplierForm" />
        <div className={styles.fieldContainer}>
          <div>
            <label>Name*</label>
            <input
              type="text"
              {...formik.getFieldProps("name")}
              style={
                formik.touched.name && formik.errors.name
                  ? { borderColor: "red" }
                  : null
              }
            />
          </div>
          <div>
            <label>Phone*</label>
            <input
              type="text"
              {...formik.getFieldProps("phone")}
              style={
                formik.touched.phone && formik.errors.phone
                  ? { borderColor: "red" }
                  : null
              }
            />
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <div>
            <label>Email*</label>
            <input
              type="email"
              {...formik.getFieldProps("email")}
              style={
                formik.touched.email && formik.errors.email
                  ? { borderColor: "red" }
                  : null
              }
            />
          </div>
          <div>
            <label>Company Name*</label>
            <input
              type="text"
              {...formik.getFieldProps("companyName")}
              style={
                formik.touched.companyName && formik.errors.companyName
                  ? { borderColor: "red" }
                  : null
              }
            />
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <div>
            <label>PRODUCTS YOU CAN SUPPLY*</label>
            <textarea
              {...formik.getFieldProps("productSupply")}
              rows={8}
              style={
                formik.touched.productSupply && formik.errors.productSupply
                  ? { borderColor: "red" }
                  : null
              }
            />
          </div>
          <div>
            <label>Message</label>
            <textarea
              {...formik.getFieldProps("message")}
              rows={8}
              style={
                formik.touched.message && formik.errors.message
                  ? { borderColor: "red" }
                  : null
              }
            />
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <Button
            className={
              !(formik.isValid && formik.dirty) ? "disabledBtn" : "submitBtn"
            }
            type="submit"
          >
            SUBMIT
          </Button>
        </div>
      </form>
    </div>
  );
};

export default SupplierForm;
