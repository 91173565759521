import React, { useState } from "react";
import Slider from "react-slick";
import styles from "./style.module.scss";
import { Col, Container, Row } from "react-bootstrap";
import { Text } from "../../styledComponent";
import { LineDivider, FaqBox } from "../../ui";
import {
  productGallerySliderSettings,
  productMainSliderSettings,
} from "../../../utils/constants";

const CommonProductPage = (props) => {
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);
  const [activeFaq, setActiveFaq] = useState(false);

  return (
    <div className={styles.commonProductPage}>
      <Container className={`sectionContainer ${styles.commonPageInner}`}>
        <Row>
          <Col lg={6}>
            {props.gallery && (
              <>
                <div className={styles.productMainSlider}>
                  <Slider
                    {...productMainSliderSettings}
                    ref={(slider) => setSlider1(slider)}
                    asNavFor={slider2}
                  >
                    {props.gallery.map((val, i) => (
                      <div key={i}>
                        <img src={val} alt={props.title} />
                      </div>
                    ))}
                  </Slider>
                </div>
                <div className={styles.productGallerySlider}>
                  <Slider
                    {...productGallerySliderSettings}
                    asNavFor={slider1}
                    ref={(slider) => setSlider2(slider)}
                  >
                    {props.gallery.map((val, i) => (
                      <div key={i}>
                        <img src={val} alt={props.title} />
                      </div>
                    ))}
                  </Slider>
                </div>
              </>
            )}
          </Col>
          <Col lg={6}>
            <Text className={styles.productTitle}>{props.title}</Text>
            <LineDivider />
            <div
              className={styles.productDescription}
              dangerouslySetInnerHTML={{ __html: props.description }}
            ></div>
            {props.features && (
              <ul className={styles.featureList}>
                {props.features.map((data, i) => (
                  <FaqBox
                    title={data.title}
                    description={data.content}
                    id={i}
                    key={i}
                    currentQues={activeFaq}
                    currentFaq={(val) => setActiveFaq(val)}
                  />
                ))}
              </ul>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CommonProductPage;
