import React from "react";
import { Helmet } from "react-helmet";
import styles from "./style.module.scss";
import { ContactSection, PageBanner } from "../../components/parts/section";

const Contact = () => {
  return (
    <>
      <Helmet>
        <title>
          APF Steel, Leading Steel Supplier & Metal Processing Company
        </title>
        <meta
          name="description"
          content="Are you looking for a company specializing in structural steel supply, fabrication, or prototyping metals & services in Calgary? Contact APF."
        />
      </Helmet>
      <div className={styles.contactPage}>
        <PageBanner title="CONTACT US" />
        <ContactSection />
      </div>
    </>
  );
};

export default Contact;
