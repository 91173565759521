import React from "react";
import { Helmet } from "react-helmet";
import styles from "./style.module.scss";
import { PageBanner } from "../../components/parts/section";
import { BackgroundOverlay } from "../../components/ui";
import { Container, Row, Col } from "react-bootstrap";
import { SectionTitle, Text } from "../../components/styledComponent";
import { SupplierForm } from "../../components/parts";

const Supplier = () => {
  return (
    <>
      <Helmet>
        <title>APF Fitting, Sister Company of APF Steel, Calgary</title>
        <meta
          name="description"
          content="With APF Fitting's expertise, you will have access to a wide range of high-quality products and solutions to meet your market needs."
        />
      </Helmet>
      <div className={styles.supplierPage}>
        <PageBanner title="SUPPLIERS" />

        {/* Second section */}
        <Container className={`sectionContainer ${styles.aboutSecondSection}`}>
          <Row>
            <Col lg={5}>
              <SectionTitle>
                APF Steel is positioned to create value in your supply chain
              </SectionTitle>
              <BackgroundOverlay color={"rgb(255,255,255,0.9)"} />
            </Col>
            <Col lg={7}>
              <Text>
                As part of the APF Group, APF Steel’s expertise gives you a wide
                range of high-quality tools and solutions to assist you in
                bringing your products to market. Our knowledge will expand the
                reach of your brand and assist in your go-to market strategy.
                <br />
                <br />
                APF Group’s leadership has years of experience in distribution.
                Through these years, APF Group has established an incredible
                network of buyers and suppliers. This extensive network will
                give you access to the optimal channels for your products.
                <br />
                <br />
                Our state of the art warehouse allows us to ensure that each
                delivery is carefully and perfectly facilitated.
              </Text>
            </Col>
          </Row>
        </Container>

        {/* Supplier Form section */}
        <div className={styles.supplierFormSection}>
          <Container className={`sectionContainer`}>
            <Row>
              <Col lg={5}>
                <SectionTitle>Get Started</SectionTitle>
                <Text>
                  Are you ready to become a vendor and take advantage of APF
                  Steel industry connections?
                  <br />
                  <br />
                  Fill out the partner contact form today!
                </Text>
              </Col>
              <Col lg={7}>
                <SupplierForm />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default Supplier;
