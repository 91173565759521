import { Routes, Route } from "react-router-dom";
import "./App.scss";
import { Header, Footer } from "./components/parts";
import {
  About,
  Contact,
  Home,
  PageNotFound,
  Products,
  Supplier,
} from "./screens";
import { routes } from "./utils/routes";
import {
  ColorCoatedSteel,
  ColorRolledSteel,
  GalvanizedIronPipes,
  HollowPipes,
  HotGalvanizedSteel,
  HotGalvanizedWire,
  MsPipes,
  OCTGWelded,
  PreGalvanizedTubes,
  ScaffoldingPipes,
  TemporaryFence,
} from "./screens/products/innerPages";

function App() {
  return (
    <main>
      <Header />
      <Routes>
        <Route path={routes.HOME} element={<Home />} />
        <Route path={routes.CONTACT} element={<Contact />} />
        <Route path={routes.ABOUT} element={<About />} />
        <Route path={routes.SUPPLIER} element={<Supplier />} />
        <Route path={routes.PRODCUTS} element={<Products />} />
        <Route
          path={routes.PRE_GALVANIZED_TUBES}
          element={<PreGalvanizedTubes />}
        />
        <Route
          path={routes.GALVANIZED_IRON_PIPES}
          element={<GalvanizedIronPipes />}
        />
        <Route path={routes.SCAFFOLDING_PIPES} element={<ScaffoldingPipes />} />
        <Route path={routes.MS_PIPES} element={<MsPipes />} />
        <Route path={routes.HOLLOW_PIPE} element={<HollowPipes />} />
        <Route path={routes.TEMP_FENCE} element={<TemporaryFence />} />
        <Route path={routes.OCTG_WELDED} element={<OCTGWelded />} />
        <Route path={routes.GALVANIZED_WIRE} element={<HotGalvanizedWire />} />
        <Route path={routes.GALVANIZED_STEEL} element={<HotGalvanizedSteel />} />
        <Route path={routes.COLOR_COATED_STEEL} element={<ColorCoatedSteel />} />
        <Route path={routes.COLOR_ROLLED_STEEL} element={<ColorRolledSteel />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <Footer />
    </main>
  );
}

export default App;
