import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styles from "./style.module.scss";
import {
  GeoAltFill,
  TelephoneFill,
  ChevronRight,
  Envelope,
  Facebook,
  Linkedin,
} from "react-bootstrap-icons";
import { Anchor, SectionTitle, Text } from "../../styledComponent";
import { footerNavMenuItems } from "../../../utils/constants";
import apfTiresLogo from "../../../assets/apfTires.png";
import apfFitLogo from "../../../assets/apfFittings.png";
import { routes } from "../../../utils/routes";

const Footer = () => {
  return (
    <footer>
      <Container>
        <Row className={styles.footerInner}>
          <Col lg={4} className={styles.footerColOne}>
            <Text>
              The steel products offered by APF Steel are of the highest quality
              in the Calgary and surrounding areas. Hollow structural steel
              tubes, galvanized steel pipe, scaffolding steel pipe, OCTG
              seamless and welded tubes, and cold rolled steel are all products
              we supply to the Oil and Gas industry in Calgary. Count on us for
              exceptional products offered at competitive prices as your local
              HSS supplier in Calgary.
            </Text>
            <div className={styles.footerCompanyLogo}>
              <a href="http://www.apftires.ca/" target="_blank" rel="noreferrer"><img src={apfTiresLogo} alt="APF TIRES"/></a>
              <a href="https://apfittings.com/" target="_blank" rel="noreferrer"><img src={apfFitLogo} alt="APF FITTINGS"/></a>
            </div>
          </Col>
          <Col lg={5} className={styles.footerColTwo}>
            <SectionTitle>Navigation</SectionTitle>
            <ul className={styles.footerProductList}>
              {footerNavMenuItems.map((val, i) => {
                return (
                  <li key={i}>
                    <a href={val.link}>
                      <ChevronRight />
                      <span>{val.name}</span>
                    </a>
                  </li>
                );
              })}
            </ul>
          </Col>
          <Col lg={3} className={styles.footerColThree}>
            <SectionTitle>Contact Us</SectionTitle>
            <ul className={styles.contactDetailsBox}>
              <li>
                <a href="https://maps.app.goo.gl/a1xpGpShJStM5jSn8">
                  <GeoAltFill />
                  <span>6802 39 Street Leduc, AB T9E 0Z4</span>
                </a>
              </li>
              <li>
                <a href="https://maps.app.goo.gl/EPt42uNe9tvMBHWV8">
                  <GeoAltFill />
                  <span>Unit 2109, 6027-79th AVE SE, CALGARY, AB. T2C 5P1</span>
                </a>
              </li>
              <li>
                <a href="tel:4032790319">
                  <TelephoneFill />
                  <span>(403) 279-0319</span>
                </a>
              </li>
              <li>
                <a href="tel:4032639005">
                  <TelephoneFill />
                  <span>(403) 263-9005</span>
                </a>
              </li>
              <li>
                <a href="mailto:info@apfsteel.com">
                  <Envelope />
                  <span>info@apfsteel.com</span>
                </a>
              </li>
            </ul>
            <SectionTitle>Working Hours</SectionTitle>
            <div className={styles.hoursBlock}>
              <div>
              <Text>Monday-Friday</Text>
              <Text>8AM - 4:30PM</Text>
              </div>
              <div>
              <Text>Saturday & Sunday</Text>
              <Text>Closed</Text>
              </div>
            </div>
            <div className={styles.socialIconBox}>
              <div>
                <a href="https://www.facebook.com/people/APF-STEEL-LTD/100070415165768/"><Facebook color="var(--secondaryColor)" size={25}/></a>
                <a href="https://www.linkedin.com/company/apf-steel-ltd/"><Linkedin color="var(--secondaryColor)" size={25}/></a>
              </div>
              <div>
                <Anchor href={routes.CONTACT}>SEND US AN ENQUIRY</Anchor>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className={styles.bottomFooter}>
        <Row>
          <Col lg={6}>
              <Text>Copyright © 2024 ALBERTA STEEL</Text>
          </Col>
          <Col lg={6}>
              <Text><a href="https://x360digital.com/" target="_blank" rel="noreferrer">Website Design and SEO by x360 Digital Inc.</a></Text>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
