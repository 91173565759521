import React from "react";

const LineDivider = (props) => {
  const style = {
    borderTop: `4px solid ${props.color ? props.color : "var(--primaryColor)"}`,
    opacity: "1",
    width: "100%",
    maxWidth: props.width ? props.width : "80px" 
  };
  return <hr style={style} />;
};

export default LineDivider;
