import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styles from "./style.module.scss";
import { BackgroundOverlay } from "../../../ui";
import { SectionTitle } from "../../../styledComponent";
import pageBannerImg from "../../../../assets/pageBanner.jpg";

const PageBanner = (props) => {
  return (
    <div
      className={styles.bannerContainer}
      style={{
        backgroundImage: `url(${props.image ? props.image : pageBannerImg})`, ...props.style
      }}
    >
      {props.title && (
        <Container className={styles.bannerInner}>
          <Row>
            <Col>
              <SectionTitle>{props.title}</SectionTitle>
            </Col>
          </Row>
        </Container>
      )}
      <BackgroundOverlay />
    </div>
  );
};

export default PageBanner;
