export const contactFormInitialValues = {
    name: '',
    phone: '',
    email: '',
    productIntrestedIn: '',
}

export const supplierFormInitialValues = {
    name: '',
    phone: '',
    email: '',
    companyName: '',
    productSupply: '',
    message: '',
}